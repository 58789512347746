import React, { useEffect, useState } from 'react'
import style from "./PHProtocol.module.scss";
import { Row, Col, Button, Typography } from 'antd';
import { useProtocolContext } from '@ph/subject/src/context/ProtocolContext';
import { PROTOCOL_FETCH } from '@ph/subject/src/api_services/Protocol/protocolFetch';
import { Checkbox } from "antd";
import {
    MinusOutlined,
    PlusOutlined
} from '@ant-design/icons';
import ProtocolReadMoreDescContainer from './ProtocolReadMoreDescContainer';
import { convertPrice } from '@ph/common/global/Helpers';

const { Text } = Typography;


function StepProduct({ productData, stepId, stepProductsId, allowQuatity, minNumberOfSelection, showDiscountVariant }) {

    const { selectedResult, updateSelectionResult } = useProtocolContext()

    const isProductPresent = (productId) => {
        if (!selectedResult.hasOwnProperty(stepId) ||
            !selectedResult[stepId].hasOwnProperty(stepProductsId)) {
            return false; // stepId or stepProductId not present
        }

        const productsArray = selectedResult[stepId][stepProductsId];
        // console.log(stepId, stepProductsId, productId, productsArray.some(product => product.product_id === productId))
        return productsArray.some(product => product.product_id === productId);
    };

    return (
        <>
            {productData.map(product => (
                <Product
                    key={product.product_id}
                    product={product}
                    isProductPresent={(id) => isProductPresent(id)}
                    stepId={stepId}
                    stepProductsId={stepProductsId}
                    onProductClick={(data) => updateSelectionResult(stepId, stepProductsId, data, minNumberOfSelection, 1, showDiscountVariant)}
                    onQuantityUpdate={(data, quantity) => updateSelectionResult(stepId, stepProductsId, data, minNumberOfSelection, quantity, showDiscountVariant)}
                    allowQuatity={allowQuatity}
                    showDiscountVariant={showDiscountVariant}
                />
            ))}
        </>

    )
}


export default StepProduct

const Product = ({ product, onProductClick, stepId, stepProductsId, allowQuatity, showDiscountVariant }) => {
    const [selected, setSelected] = useState(false)
    const { selectedResult, modifySelectedResult, updateQuantitySelectionResult, mainProtocol } = useProtocolContext()
    const [productData, setProductData] = useState({})
    const [loading, setLoading] = useState(false)
    const [productQuantity, setProductQuantity] = useState(0)

    const handleProductClick = (event) => {
        event.stopPropagation()
        onProductClick(productData)
        setProductQuantity(1)
        setSelected(true)
    }

    const handleProductDiselect = (event) => {
        event.stopPropagation()
        updateQuantitySelectionResult(stepId, stepProductsId, productData, 0)
        setProductQuantity(0)
        setSelected(false)
    }

    const getProductPresent = (productId) => {
        if (!selectedResult.hasOwnProperty(stepId) ||
            !selectedResult[stepId].hasOwnProperty(stepProductsId)) {
            return false; // stepId or stepProductId not present
        }

        const productsArray = selectedResult[stepId][stepProductsId];
        if (productsArray.some(product => product.product_id === productId)) {
            const product = productsArray.filter(product => product.product_id === productId)
            return product[0]
        }
        // console.log(stepId, stepProductsId, productId, productsArray.some(product => product.product_id === productId))
        return null
    };

    const handleOnMinusClick = () => {

        setProductQuantity(productQuantity - 1)
        updateQuantitySelectionResult(stepId, stepProductsId, productData, productQuantity - 1)
    }

    const handleOnPlusClick = () => {

        setProductQuantity(productQuantity + 1)
        updateQuantitySelectionResult(stepId, stepProductsId, productData, productQuantity + 1)

    }

    useEffect(() => {
        const productinfo = getProductPresent(product.product_id)
        if (productinfo) {
            setProductQuantity(productinfo?.quantity ?? 1)
            setSelected(true)
        } else {
            setProductQuantity(0)
            setSelected(false)
        }
    }, [selectedResult])


    useEffect(() => {
        if (product?.fetch_id) {
            setLoading(true)
            PROTOCOL_FETCH[product?.fetch_id].onFetch().then(res => {
                let updatedProduct = {
                    ...product,
                    ...res
                }
                if (showDiscountVariant && updatedProduct?.product_id !== mainProtocol?.product_id && res?.product_discount_variant_price) {
                    updatedProduct = {
                        ...updatedProduct,
                        product_price: res?.product_discount_variant_price,
                        product_variant_id: product?.discount_variant_id,
                        product_discount_price: res?.product_variant_price,
                    }
                }
                setProductData(updatedProduct)
                setTimeout(() => {
                    modifySelectedResult(stepId, stepProductsId, product.product_id, updatedProduct)
                }, 100)

            })
                .catch(error => console.error(error))
                .finally(() => setLoading(false))
        } else {
            setProductData(product)
        }
    }, [])

    useEffect(() => {
        if (showDiscountVariant && productData?.product_id) {
            if (mainProtocol?.product_id === productData?.product_id) {
                const updatedProduct = {
                    ...productData,
                    product_price: productData?.product_md03_price,
                    product_variant_id: productData?.product_md03_variant,
                    product_discount_price: productData?.product_md03_discount_price
                }
                setProductData(updatedProduct)
                setTimeout(() => {
                    modifySelectedResult(stepId, stepProductsId, product.product_id, updatedProduct)
                }, 100)
            } else {
                const updatedProduct = {
                    ...productData,
                    product_price: productData?.product_discount_variant_price,
                    product_variant_id: productData?.discount_variant_id,
                    product_discount_price: productData?.product_variant_price,
                }
                setProductData(updatedProduct)
                setTimeout(() => {
                    modifySelectedResult(stepId, stepProductsId, product.product_id, updatedProduct)
                }, 100)
            }

        }
    }, [mainProtocol])

    const getButtonText = (selectionFlag) => {
        if (selectionFlag && product?.is_included) {
            return "INCLUDED"
        } else if (selectionFlag) {
            return "ADDED"
        } else {
            return "ADD"
        }

    }

    if (!product && !product?.product_title) {
        return <></>
    } else {



        return (
            <Row className={`${style.product} ${selected || productQuantity ? style.selected : ''}`} onClick={handleProductClick} >
                {allowQuatity && productQuantity > 0 ? (
                    <>
                        <IncrementDecrementButton
                            productQuantity={productQuantity}
                            onMinusClick={handleOnMinusClick}
                            onPlusClick={handleOnPlusClick}
                        />
                        <Checkbox className={style.checkbox} checked={productQuantity || selected} onClick={handleProductDiselect} />
                    </>
                ) : (
                    <>
                        <ProductAddText text='INCLUDED' showFlag={product?.is_included} />
                        <Checkbox className={style.checkbox} checked={productQuantity || selected} onClick={handleProductClick} />
                    </>
                )}
                <Col xs={24}>
                    <div className={`${style.productDetail} ${style.topSection}`}>
                        <ProductDiscount productData={productData} />
                        <Text className={style.productPrice}>{productData?.product_price ? `$${convertPrice(productData?.product_price)}/mo` : 'Free'}</Text>
                    </div>
                </Col>
                <Col xs={productData.product_image ? 18 : 24}>
                    <div className={style.productDetail}>
                        <Text className={style.productTitle}>{productData?.product_title}</Text>
                        {/* <Text className={style.productDesc}>{product?.product_description}</Text> */}
                        <ProtocolReadMoreDescContainer
                            description={productData?.product_description}
                            productId={productData?.product_id}
                        />
                        {/* {allowQuatity && productQuantity > 0 ? (
                        <IncrementDecrementButton
                            productQuantity={productQuantity}
                            onMinusClick={handleOnMinusClick}
                            onPlusClick={handleOnPlusClick}
                        />
                    ) : (
                            <Button
                                type={"secondary"}
                                className={`${style.addButton} ${productQuantity || selected ? style.selected : ''}`}
                                onClick={handleProductClick}
                            >
                                {productQuantity || selected ? getButtonText(true) : getButtonText(false)}
                            </Button>
                    )} */}

                    </div>
                </Col>
                {productData.product_image && (<Col xs={6}>
                    <div className={style.productImage}>
                        <img src={productData.product_image} alt="" />
                    </div>
                </Col>)}
            </Row>
        )
    }
}

const ProductDiscount = ({ productData }) => {

    if (productData?.product_price && productData?.product_discount_price) {
        return (
            <Text className={style.discountPrice}>
                {productData?.product_discount_price ? `$${convertPrice(productData?.product_discount_price)}` : ''}
            </Text>
        )
    }
    return <></>
}

const IncrementDecrementButton = ({ productQuantity, onMinusClick, onPlusClick }) => {
    return (
        <div className={style.incrementButton}>
            <Button
                type="primary"
                icon={<MinusOutlined className={style.buttonIcon} />}
                className={style.minusButton}
                onClick={(event) => {
                    event.stopPropagation()
                    onMinusClick()
                }}
            />
            <Text className={style.count}>{productQuantity}</Text>
            <Button
                type="primary"
                icon={<PlusOutlined className={style.buttonIcon} />}
                className={style.plusButton}
                onClick={(event) => {
                    event.stopPropagation()
                    onPlusClick()
                }}
            />
        </div>
    )
}

const ProductAddText = ({ text = "INCLUDED", showFlag }) => {
    return (
        <div className={`${style.incrementButton} ${!showFlag ? 'hide' : ''}`} >
            <Text className={style.count}>{text}</Text>
        </div >
    )
}