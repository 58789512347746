import React from 'react'
import style from "./PHProtocol.module.scss";
import StepProduct from './StepProduct';
import StepProductMultiple from './StepProductMultiple';
import { PROTOCOL_STEP_CARD_TYPES } from '@ph/common/constants/Protocol/constants';

function StepProducts({ products, stepId }) {
    return (
        <div className={style.productsContainer}>
            {products.map(product => (
                <StepCardsSelector key={product.id} productType={product.type} product={product} stepId={stepId} />
            ))}
        </div>
    )
}

export default StepProducts

const StepCardsSelector = ({ productType, product, stepId }) => {
    switch (true) {
        case productType === PROTOCOL_STEP_CARD_TYPES.PRODUCT && product.is_multiple:
            return <StepProductMultiple rootProduct={product?.rootProduct} productData={product.data} stepId={stepId} stepProductsId={product.id} defaultSelectId={product?.defaultSelectId} allowQuatity={product?.allowQuatity} minNumberOfSelection={product.minNumberOfSelection} />

        case productType === PROTOCOL_STEP_CARD_TYPES.PRODUCT && !product.is_multiple:
            return <StepProduct productData={product.data} stepId={stepId} stepProductsId={product.id} defaultSelectId={product?.defaultSelectId} allowQuatity={product?.allowQuatity} minNumberOfSelection={product.minNumberOfSelection} showDiscountVariant={product?.showDiscountVariant} />

        default:
            return <StepProduct productData={product.data} stepId={stepId} stepProductsId={product.id} defaultSelectId={product?.defaultSelectId} allowQuatity={product?.allowQuatity} minNumberOfSelection={product.minNumberOfSelection} showDiscountVariant={product?.showDiscountVariant} />
    }
}